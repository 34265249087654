import { render, staticRenderFns } from "./scene24.vue?vue&type=template&id=bbb17d86&scoped=true&"
import script from "./scene24.vue?vue&type=script&lang=ts&"
export * from "./scene24.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bbb17d86",
  null
  
)

export default component.exports