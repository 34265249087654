









import Vue from "vue";
import Component from "vue-class-component";
import {Prop} from "vue-property-decorator";
import {Roles} from "@/Interfaces/sessionInterface";
import NextSceneButton from "@/components/sceneItems/NextSceneButton.vue";
@Component({
  components: {NextSceneButton}
})
export default class scene1_22 extends Vue {
  @Prop({required:true})role!: Roles
  @Prop({required:true})video!: string
}
