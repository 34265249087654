








import Vue from "vue";
import Component from "vue-class-component";
import {Prop, Watch} from "vue-property-decorator";
import textFit from "textfit";

@Component
export default class Missie extends Vue {
  $refs!: {
    text: HTMLDivElement
  }
  @Prop({required:true}) text!: string

  @Watch('text')
  onTextChange(){
    Vue.nextTick().then(() => {
      textFit(this.$refs.text, { maxFontSize: 5000});
    })
  }

  mounted(){
    textFit(this.$refs.text,{maxFontSize: 5000});
  }
}
