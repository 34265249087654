

























import {Component, Prop, Vue} from "vue-property-decorator";
import {GameData, Roles, User} from "@/Interfaces/sessionInterface";
import NextSceneButton from "@/components/sceneItems/NextSceneButton.vue";

@Component({
  components: {NextSceneButton}
})
export default class TussenStand extends Vue {
  @Prop() role!: Roles

  get position(){
    if(this.role ==='user'){
      let position=-1
      this.orderedList.forEach((value, index) => {
        console.log(value[0])
        console.log(this.$store.state.userId)
        if(value[0]===this.$store.state.userId){
          position =index+1
        }
      })
      return position
    }
    return;
  }

  getCorrectAnswers(gameData: GameData) {
    if(gameData.submittedAnswers == null){
      return 0
    }
    return Object.values(gameData.submittedAnswers).filter((value) => value.isCorrect).length
  }

  get usersWithCorrectAswers() {
    const users = this.$store.state.sessionData.users
    const usersWithCorrectAnswers = Object.entries(users).map((value) => {
      const user: User = value[1] as User
      return [value[0], {name:user.name, correctAnswers: this.getCorrectAnswers(user.gameData ?? {submittedAnswers: {}})}]
    })
    return Object.fromEntries(usersWithCorrectAnswers)
  }

  get orderedList(){
    let list =Object.entries<{username:string,correctAnswers:number}>(this.usersWithCorrectAswers)
    return list.sort((a, b) => {
      return b[1].correctAnswers-a[1].correctAnswers
    })
  }

}
